import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的公司列表
export function GetCompaniesAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/companies/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取公司的分页列表
export function GetCompaniesList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/companies/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的公司数据
export function GetCompaniesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/companies/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取具体的公司设置数据
export function GetCompaniesSelf() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/companies/self`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改当前公司的信息
export function PutCompaniesSelf(params={}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/companies/self`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 修改指定公司的数据
export function PutCompaniesById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/companies/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 重置管理员
export function PutResetAdmin(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/companies/${id}/admin/reset`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 禁用\启用指定公司
export function ToggleDisableCompaniesById(id = "",bool=null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/companies/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 新建公司
export function PostCompanies(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/companies`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量新增
export function MultiplePostCompanies(params = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        params.forEach(item => {
            multipleRequests.push(apiv1.post(`/v1/companies`, item))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })

}

/**
 * 动态设置启用\禁用
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function ToggleMultipleDisableCompanies(multipleSelectieIds = [],bool=null) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.put(`/v1/companies/${item}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}