<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="companyForm"
    label-width="100px"
    class="company-ruleForm"
    :disabled="submitting"
    v-loading="loading"
  >
    <el-form-item label="公司名称" prop="name">
      <el-input
        v-model.trim="form.name"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="公司联系人" prop="contactName">
      <el-input
        v-model.trim="form.contactName"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系人电话" prop="contactPhoneNo">
      <el-input
        v-model.trim="form.contactPhoneNo"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>

    <el-form-item label="用户数上限" prop="userCountLimit">
      <el-input-number
        v-model="form.userCountLimit"
        :min="0"
        :max="1000"
        label="请输入"
      ></el-input-number>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >取 消</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('companyForm')"
        >提交新建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('companyForm')"
        v-if="!isEmprty(id)"
        >提交修改</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PostCompanies, PutCompaniesById, GetCompaniesById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import common from "../mixins/common";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  mixins: [common],
  data() {
    return {
      loading: false,
      submitting: false,
      form: {
        name: "",
        userCountLimit: 10,
        contactName: "",
        contactPhoneNo: "",
      },
      rules: {
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        contactName: [
          { required: true, message: "请输入公司联系人", trigger: "blur" },
        ],
        contactPhoneNo: [
          {
            required: true,
            validator: this.checkPhoneNumber,
            trigger: "blur",
          },
        ],
        userCountLimit: [
          {
            required: true,
            message: "请输入用户数上限",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.inintForm();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPhoneNumber(rule, value, callback) {
      // console.log('rule', rule)
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      }
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("电话号码格式不正确"));
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostCompanies(this.form)
            .then((res) => {
              let { adminUsername, adminInitPassword } = res.data;
              this.submitting = false;
              this.showAddResult("公司创建成功，以下为公司管理员的账号密码", adminUsername, adminInitPassword).then(() => {
                this.onEmitSuccess();
                this.inintForm();
              });
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },

    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutCompaniesById(this.id, this.form)
            .then(() => {
              this.submitting = false;
              this.onEmitSuccess();
              this.inintForm();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetCompaniesById(id)
        .then((res) => {
          let { name, userCountLimit, contactName, contactPhoneNo } = res.data;
          this.form = {
            name: name,
            userCountLimit: userCountLimit,
            contactName: contactName,
            contactPhoneNo: contactPhoneNo,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onCancel() {
      this.$emit("cancel");
    },
    onEmitSuccess() {
      this.$emit("success");
    },
    inintForm() {
      this.form = {
        name: "",
        userCountLimit: 10,
        contactName: "",
        contactPhoneNo: "",
      };
    },
  },
};
</script>

<style>
</style>