<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <keep-alive><FilterSelection @change="onFilterChange" /></keep-alive>
    <basic-table
    hiddenClear
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          @click="addRow"
          type="primary"
          v-if="checkPermission(['SUPER_ADMIN'])"
          class="mt-r"
          >新增</el-button
        >
        <!-- <el-button size="small" class="mt-r" @click="multipleFormDialog = true" >批量新增</el-button> -->
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="checkPermission(['SUPER_ADMIN'])"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              修改
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled && checkPermission(['SUPER_ADMIN'])"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              禁用
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled && checkPermission(['SUPER_ADMIN'])"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              启用
            </el-button>
            <el-dropdown v-if="checkPermission(['SUPER_ADMIN'])">
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="checkPermission(['SUPER_ADMIN'])"
                  @click.native.prevent="
                    handleResetAdmin(scope.row, scope.$index)
                  "
                  >重置管理员</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="checkPermission(['SUPER_ADMIN'])"
          type="success"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="enableLoading"
          >批量启用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          v-if="checkPermission(['SUPER_ADMIN'])"
          type="danger"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="disableLoading"
          >批量禁用 {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      :title="isEmprty(currentId) ? '新增公司' : '修改公司'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-dialog
      title="批量新增"
      :visible.sync="multipleFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import {
  GetCompaniesList,
  PutResetAdmin,
  ToggleMultipleDisableCompanies,
} from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import common from "./mixins/common";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import FilterSelection from "./components/FilterSelection";
import { FormatMultipleItemIds, GetTableDisableMessage, ReadLocalColumn } from "@/utils/table";
export default {
  components: {
    BasicTable,
    Form,
    MultipleForm,
    PageHeader,
    FilterSelection
  },
  mixins: [common],
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      enableLoading: false,
      disableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: 'left',
            sortable: "custom",
          },
          {
            prop: "name",
            label: "名称",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "contactName",
            label: "公司联系人",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "contactPhoneNo",
            label: "联系人电话",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "adminUsername",
            label: "管理员用户名",
            showColumn: true,
            sortable: "custom",
          },
          
          {
            prop: "userCountLimit",
            label: "用户数上限",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "adminUsername",
            label: "管理员用户名",
            showColumn: false,
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "adminInitPassword",
            label: "管理员初始密码",
            showColumn: false,
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            width:200,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 160,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {...this.tableFilters};
    },
    getTableOrderParams() {
      if (this.orderProp !== "" && this.orderDirection !== null) {
        return `${this.orderProp},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetCompaniesList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "";
      this.orderDirection = null;
      this.onRefresh();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      this.orderDirection = order;
      if (order === null) {
        this.orderProp = "";
      } else {
        this.orderProp = prop;
      }
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      this.currentId = row.id;
      this.formDialog = true;
      console.log("row", row);
    },
    onFormCancel() {
      this.currentId = "";
      this.formDialog = false;
      this.multipleFormDialog = false;
    },
    onFormSuccess() {
      this.formDialog = false;
      this.multipleFormDialog = false;
      this.currentId = "";
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    disableRow(row) {
      this.$msgbox({
        title: "禁用提示",
        message: `是否确定要禁用【${row.name}】`,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToggleMultipleDisableCompanies([row.id], true)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },
    enableRow(row) {
      this.$msgbox({
        title: "启用提示",
        message: `是否确定要启用【${row.name}】`,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToggleMultipleDisableCompanies([row.id], false)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },
    handleResetAdmin(row) {
      let username = "";
      let password = "";
      let tempName = row.name;
      this.$msgbox({
        title: "重置提示",
        message: "是否确定要重置该公司的管理员？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            PutResetAdmin(row.id)
              .then((res) => {
                let { name, adminUsername, adminInitPassword } = res.data;
                username = adminUsername;
                password = adminInitPassword;
                tempName = name;
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      }).then((action) => {
        if (action === "confirm") {
          this.showAddResult(`${tempName}管理员重置成功，重置后的账号密码为`, username, password).then(() => {
            this.onRefresh();
          });
        }
      });
    },

    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "content",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
      });

      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            this.toggleDisableLoading = true;
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToggleMultipleDisableCompanies(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.toggleDisableLoading = false;
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                this.toggleDisableLoading = false;
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>