export default {
    methods: {
        showAddResult(title="创建成功", adminUsername = "", adminInitPassword = "") {
            return new Promise((resolve, reject) => {
                const h = this.$createElement;
                let content = h("div", null, [
                    h(
                        "p",
                        { style: "color: red" },
                        "用户名和密码只显示一次，请妥善保存！"
                    ),
                    h("p", null, `管理员账号：${adminUsername}`),
                    h("p", null, `管理员密码：${adminInitPassword}`),
                ]);
                this.$alert(content, title, {
                    confirmButtonText: "确定",
                    callback: () => {
                        resolve();
                        reject();
                    },
                });
            })

        },
    }
}